import React, { useEffect, useRef, useState } from "react";
import { CheckoutProduct } from "../models/product";
import User from "../models/user";
import checkoutService from "../services/checkoutService";


declare global {
    interface Window {
		PaymentRequest: any
    }
}
declare global {
	namespace JSX {
		interface IntrinsicElements {
			'apple-pay-button': any;
		}
	  }
}

const ApplePay = (props: any) => {

	// const [applePayTerms, setApplePayTerms] = useState(false);
	const { user, countries, provinces, handlePayment, handleUserAndProductUpdate } = props;

	const productRef: any = useRef();
    const [productCopy, setProductCopy] = useState<CheckoutProduct>({} as CheckoutProduct);
    const [tax, setTax] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

	useEffect(() => {
        setProductCopy(props.product);
        productRef.current = props.product
    }, [props.product])

    useEffect(() => {
        if (total) {
            setProductCopy({ ...productRef.current, totalTax: +tax, orderGrandTotal: +total });

        }
    }, [tax, total])  //eslint-disable-line

    useEffect(() => {
        productRef.current = productCopy as any;
    }, [productCopy])

	
	function getAppleConfigs(key) {
		let value = "";
		switch (key) {
			case 'merchantId':
				value = props.config.applePay.merchantId;
				break;
			case 'merchantName':
				value = props.config.applePay.merchantName;
				break;
			case 'applePayEnabled':
				value = props.config.applePay.enable;
				break;
		}
		return value;
	}

	async function validateMerchant(event) {
		alert('validateMerchant clicked')
		let data = {
			url: event.validationURL,
			body: {
				merchantIdentifier: getAppleConfigs("merchantId"),
				displayName: getAppleConfigs("merchantName"),
				initiative: "web",
				initiativeContext: window.location.hostname
			}
		}

		return await checkoutService.GetApplePayMerchantSession(data);
		
	}


	async function onApplePayButtonClicked() {

		if (!window.PaymentRequest) {
			return;
		}

		// if (!applePayTerms) {
		// 	return;
		// }

		try {

			// Define PaymentMethodData
			const paymentMethodData = [{
				"supportedMethods": "https://apple.com/apple-pay",
				"data": {
					"version": 3,
					"merchantIdentifier": getAppleConfigs("merchantId"),
					"merchantCapabilities": [
						"supports3DS"
					],
					"supportedNetworks": [
						"amex",
						"discover",
						"masterCard",
						"visa"
					],
					"countryCode": "US"
				}
			}];

			let priceForTax = productRef.current.priceWithoutTax

			let regularPrice = productRef.current.priceWithoutTax + (productRef.current.membershipDiscount ? productRef.current.membershipDiscount : 0) //model.Cart.CartLines[0].ProductRegularPrice;
			let tax = productRef.current.totalTax
			let newTotal = productRef.current.orderGrandTotal

			if (tax && tax.includes("$")) {
				tax = tax.substring(1);

			}
			else {
				tax = "0.00";
			}

			if (newTotal && newTotal.includes("$")) {
				newTotal = newTotal.substring(1);
			}
			else {
				newTotal = priceForTax.toString();
			}

			// Define PaymentDetails
			const paymentDetails = {
				"total": {
					"label": getAppleConfigs('merchantName'),
					"amount": {
						"value": newTotal,
						"currency": "USD"
					}
				},
				"displayItems": [
					{
						"label": "Subtotal",
						"amount": {
							"value": regularPrice,
							"currency": "USD"
						}
					},
				],
				"shippingOptions": [
					{
						"id": "standardShipping",
						"label": "Digitally Shipped via email",
						"amount": {
							"value": "0.00",
							"currency": "USD"
						},
						"selected": true
					}
				],
				"modifiers": [
					{
						"supportedMethods": "https://apple.com/apple-pay",
					}
				]
			};

			paymentDetails.displayItems.push(
				{
					"label": "Tax",
					"amount": {
						"value": tax,
						"currency": "USD"
					}
				},
			)

			// Define PaymentOptions
			const paymentOptions = {
				"requestPayerName": true,
				"requestBillingAddress": false,
				"requestPayerEmail": false,
				"requestPayerPhone": false,
				"requestShipping": true,
				"shippingType": "shipping"
			};

			alert(paymentDetails)
			// Create PaymentRequest
			const request = new window.PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);

			request.onmerchantvalidation = async event => {
				const merchantSession:any = await validateMerchant(event);
				alert('merchant response received' )
				event.complete(JSON.parse(merchantSession));
			};

			request.onpaymentmethodchange = event => {
				if (event.methodDetails.type !== undefined) {
					const paymentDetailsUpdate = {
						'total': paymentDetails.total
					};
					event.updateWith(paymentDetailsUpdate);

				}
			};

			request.onshippingoptionchange = event => {
				const paymentDetailsUpdate = {
					'total': paymentDetails.total
				};
				event.updateWith(paymentDetailsUpdate);
			};

			request.onshippingaddresschange = event => {
				alert('in shipping address change')
				if (event && event.currentTarget && event.currentTarget.shippingAddress) {
					const countryId = ['us', 'united states', 'usa'].includes(event.currentTarget.country.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(event.currentTarget.country.toLocaleLowerCase()) ? 37 : 0;
					alert(event.currentTarget)
					var calculatedTotal:any = CalculateTaxForAppleOrGooglePay(event.currentTarget.shippingAddress.country, event.currentTarget.shippingAddress.postalCode, event.currentTarget.shippingAddress.region, countryId, paymentDetails);
					
					const userupdate = { ...user, countryId: countryId, country: countries.filter(a => a.key === `${countryId}`)[0].value, state: event.currentTarget.shippingAddress.administrativeArea, stateId: countryId === 37 ? provinces.filter(a => a.value === event.currentTarget.shippingAddress.administrativeArea)[0].value ?? 0 : 0 }
					const productUpdate = { ...productRef.current, orderGrandTotal: +calculatedTotal.newTotal.amount.value, totalTax: +calculatedTotal.displayItems.filter(a => a.type === 'TAX')[0].price }
		
					handleUserAndProductUpdate(userupdate, productUpdate)
					setTax(+calculatedTotal.displayItems.filter(a => a.type === 'TAX')[0].price);
					setTotal(+calculatedTotal.newTotal.amount.value);

					event.updateWith(calculatedTotal);
				}
				else {
					event.updateWith({
						'total': paymentDetails.total
					});
				}
			}

			const response = await request.show();

			var paymentDataString = JSON.stringify(response.details.token.paymentData);
			var paymentDataBase64 = btoa(paymentDataString);

			let cardTypeAndMaskedPan = getCardTypeAndMaskedPanFromApplePayResponse(response.details.token.paymentMethod)

			const firstName = response.shippingAddress.recipient.split(' ')[0];
			const lastName = response.shippingAddress.recipient.split(' ')[1]
			const newUser: User = { ...user }
			newUser.firstName = firstName;
			newUser.lastName = lastName;
			newUser.state = response.shippingAddress.administrativeArea;
			newUser.country = response.shippingAddress.countryCode;
			newUser.countryId = ['us', 'united states', 'usa'].includes(response.shippingAddress.countryCode.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(response.shippingAddress.countryCode.toLocaleLowerCase()) ? 37 : 0;
			newUser.zipCode = response.shippingAddress.postalCode;
	
			await handlePayment(paymentDataBase64, 2, cardTypeAndMaskedPan, newUser, productRef.current);
			
		}
		catch (e) {
			console.log(e);
			alert(e);
		}
	}

	
    async function CalculateTaxForAppleOrGooglePay(countryCode: string, zipCode: string, stateCode: string, countryId: number, paymentDetails:any) {

        const tax = await checkoutService.AppliedTaxByCountryCode(countryCode, countryId, stateCode, zipCode, productRef.current.priceWithoutTax)

		const paymentDetailsUpdate:any = {
			"total": {
				"label": getAppleConfigs('merchantName'),
				"amount": {
					"value": tax.totalAmount.toFixed(2),
					"currency": "USD"
				}
			},
			"displayItems": paymentDetails.displayItems,
		};
		paymentDetailsUpdate.newTotal = paymentDetails.total;
		paymentDetailsUpdate.newTotal.amount.value = tax.totalAmount.toFixed(2).toString();
	   
		paymentDetailsUpdate.displayItems.filter(a => a.label === "Tax")[0].value = `${tax.salesTaxAmount.toFixed(2)}`;
	   return paymentDetailsUpdate;
    }


	function getCardTypeAndMaskedPanFromApplePayResponse(paymentResponse) {

		let cardType = '';
		if (paymentResponse.network.toLowerCase() === "amex")
			cardType = '003'
		else if (paymentResponse.network.toLowerCase() === "discover")
			cardType = '004'
		else if (paymentResponse.network.toLowerCase() === "mastercard")
			cardType = '002'
		else if (paymentResponse.network.toLowerCase() === "visa")
			cardType = '001'
		else
			cardType = '001'

		return {
			cardType: cardType,
			maskedPan: paymentResponse.displayName.slice(Math.max(paymentResponse.displayName.length - 4, 1))
		}
	}
	return (
		<div className="apple-pay">
			<apple-pay-button buttonstyle="black" type="buy" onClick={onApplePayButtonClicked}></apple-pay-button>
		</div>
	)
}
export default ApplePay;