import axiosInstance from '../services/http';
import axios from "axios";

class CheckoutService {
    public async fetchDesignation() {
        try {
            let result = await axiosInstance.get(`/v2/masterdata/designations`);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async GetCountries() {
        try {
            let result = await axiosInstance.get(`/v2/masterdata/countries`);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async GetStates(selectedCountryId: number) {
        try {
            let result = await axiosInstance.get(`/v2/masterdata/states?countryId=${selectedCountryId}`);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async ProductDetails(trackType: string, trackPage: string) {
        try {
            const payload = {trackType: trackType,trackPage:trackPage};
            let result = await axiosInstance.post(`/checkout/product-details`, payload);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async AppliedTax(countryId: number, stateId: number, state: string, zipCode: string, priceWithoutTax: number) {
        try {
            let result = await axiosInstance.post(`/checkout/applied-tax`, {
                countryId: countryId,
                countryCode: "",
                stateId: !stateId? '' : `${stateId}`,
                stateCode: !stateId? '' : `${stateId}`,
                zipCode: zipCode,
                productPrice: priceWithoutTax,
            });
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async AppliedTaxByCountryCode(countryCode: string, countryId:number, stateCode: string, zipCode: string, priceWithoutTax: number) {
        try {
            let result = await axiosInstance.post(`/checkout/applied-tax`, {
                countryCode: countryCode,
                countryId: countryId,
                stateCode: stateCode,
                zipCode: zipCode,
                productPrice: priceWithoutTax,
            });
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async NonSSOMembership(societyAcronym: string, email: string, lastName: string, meetingID: number, trackPage: string, trackType: string) {
        try {
            const payload = {societyAcronym: societyAcronym,
                emailAddress: email,
                lastName: lastName ?? '',
                meetingId: meetingID,
                trackType: trackType,
                trackPage: trackPage };
            let result = await axiosInstance.post(`/checkout/non-sso-membership`, payload);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async SSOMembership(societyAcronym: string, email: string, lastName: string, meetingID: number, trackPage: string, trackType: string) {
        try {
            let result = await axiosInstance.post(`/checkout/sso-membership`, {
                societyAcronym: societyAcronym,
                emailAddress: email,
                lastName: lastName ?? '',
                meetingId: meetingID,
                trackType: trackType,
                trackPage: trackPage
            });
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async IsUserExist(email: string, thinkProductID: string) {
        try {
            const payload = {emailAddress: email, productId: `${thinkProductID}`};
            let result = await axiosInstance.post(`/checkout/is-user-exists`, payload);
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    public async CreateTinyUser(email: string, trackType: string, trackPage, productId) {
        try {
            const payload = {emailAddress: email, trackType, trackPage, productId:`${productId}`};
            axiosInstance.post(`/checkout/checkout-initiate`, payload);
        } catch (error) {
            this.handleError(error);
        }
    }
    
    public async CheckoutSubmitOrder(payload: any) {
        try {
            return await axiosInstance.post("checkout/submit-order", payload);
        } catch (error) {
            this.handleError(error);
        }
    }
    
    public async RegisterCheckoutUser(payload: any) {
        try {
            axiosInstance.post("checkout/register-user", payload);
        } catch (error) {
            this.handleError(error);
        }
    }
    
    public async GetApplePayMerchantSession(payload: any) {
        try {
            const result = await axiosInstance.post("checkout/merchant-session", payload);
            alert(result)
            return result.data;
        } catch (error) {
            this.handleError(error);
        }
    }
    
    private handleError(error: any) {
        // Handle the error as needed
        if (axios.isAxiosError(error)) {
            // Handle Axios error
            console.error("API Error:", error.response ? error.response.data : error.message);
            throw new Error(error.response ? error.response.data.message : error.message);
        } else {
            // Handle other errors
            console.error("Unexpected Error:", error);
            throw new Error("An unexpected error occurred.");
        }
    }
}

export default new CheckoutService(); //eslint-disable-line